.case-study-container {
    font-family: 'Gilroy-Regular', Arial, sans-serif;
    margin: 0;
    margin-top: 5%;
    padding: 20px 50px;
    background-color: white;
}

.headercase {
    display: flex;
    align-items: center; /* Центрируем элементы по вертикали */
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
}

.back-linkcase {
    position: absolute;
    top: 15%;
    font-size: 16px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    margin-right: 20px; /* Отступ от ссылки до текста */
    flex-shrink: 0;
}

.back-link a {
    position: absolute;
    top: 15%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.title-containercase {
    text-align: center;
    flex-grow: 1; /* Заголовок занимает всю оставшуюся ширину */
    margin-left: -20px; /* Слегка подправляем отступ для центрирования */
}

.industrycase {
    font-size: 1rem;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
    position: relative;
    left: -28%;
}

.titlecase {
    font-size: 11rem;
    font-weight: bold;
    margin: 0;
    /* width: 100%; */
    position: relative;
    left: 0%;
}


.info {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    font-size: 16px;
    font-family: 'Gilroy-Regular', Arial, sans-serif;
    /* border-top: 1px solid #e0e0e0; */
    margin-top: 20px;
}

.geo-age-targeting {
    flex: 2;
        font-family: 'Gilroy-Regular', Arial, sans-serif;

}

.task {
    flex: 3;
    padding-left: 20px;
}

.solution {
    flex: 4;
    padding-left: 20px;
}

.geo, .age, .targeting {
    margin-bottom: 10px;
    
}

.geo span, .age span, .targeting span, .task span, .solution span {
        font-weight: bold;

}

.targeting h3, .task h3, .solution h3 {
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
}
.targeting p {
    margin-top: -2%;
    width: 250px;
    
}
.task p {
    margin-top: 0%;
    width: 396px;

}
.solution p {
    margin-top: 0%;
    width: 546px;

}

p {
    margin-top: 0;
    line-height: 1.6;
}

.solution p {
    margin-bottom: 10px;
}

.banner {
    margin-top: 40px;
}

.results {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 80px; /* Расстояние между блоками */
    font-family: 'Gilroy-Bold', Arial, sans-serif;

}

.result {
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 20px 30px; /* Увеличиваем padding для внутреннего отступа */
    text-align: center;
    min-width: 150px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.result-value {
    font-size: 5rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px; /* Отступ между значением и меткой */
}

.result-label {
    font-size: 1.2rem;
    color: #333;
    line-height: 1;
    margin-top: auto; /* Добавляем автоматический отступ сверху */
}


.charts {
    margin-top: 40px;
}

.chart-section {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
}

.chart-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.chart-container {
    width: 200px;
    height: 200px;
    margin-left: 13%;

}

.chart-data {
    font-size: 18px;
    color: #333;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
}

.chart-data div {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;

}

.chart-data span {
    display: block;
    margin: 0 auto;
    
}

.chart-data span.value {
    font-weight: bold;
    font-size: 1.5rem;
}

.chart-data span.label {
    font-size: 0.9rem;
    color: #999;
}

.footer {
    text-align: center;
    margin-top: 50px;
}

.view-more {
    width: 1178px;
    height: 103px;
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    display: inline-block;
    position: relative;
    left: 13%;
}
.view-more:hover {
    width: 1178px;
    height: 103px;
    background-color: #D2F65A;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    display: inline-block;

}

.embedded-iframe1 {
    width: 300px;
    height: 250px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    left: -5%;
    position: relative;
    z-index: 999;
}

.null{
    height: 100px;
}

.logocase{

    position: absolute;
    top: 40px;
}