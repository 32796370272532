
  .main-container {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
    width: 100%;
    top: -5%;
  }
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-wrapper {
    margin: 54px 0 0 13px;
  }
 
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    height: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 17%;
    margin-left: 0px;
  }
  
  .info-box {
    display: flex;
    flex-direction: column;
    width:300px;
    
  }

  .description {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    letter-spacing: -0.32px;
    font-family: Gilroy-regular, sans-serif;
  }
  .gallery-button {
    justify-content: center;
    border-radius: 65px;
    background-color: #D2F65A;
    display: flex;
    margin-top: 19px;
    width: 100%;
    flex-direction: column;
    font-size: 20px;
    color: #000;
    font-weight: 600;
    text-align: right;
    letter-spacing: -0.6px;
    line-height: 120%;
    padding: 20px 25px;
    transition: background-color 0.3s, color 0.3s;
    cursor:pointer;
  }
  .gallery-button:hover {
    background-color: #000;
    color: #fff;
  }
  .button-content {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .button-text {
    margin-left:8%;
    font-family: Gilroy-medium, sans-serif;
  }
  .icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 45px;
  }
  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 20px;
    margin-left: -10%;
   margin-top:-7%;
   
  }
  .card {
    width:650px;
    height:600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom:-15%;
    cursor:pointer;
    margin-top:20%;
    font-family: Gilroy-regular, sans-serif;

    flex: 1 1 auto;
    overflow: hidden;

  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .card iframe {
    width: 100%;
    height: 500px;
    display:block;  
    pointer-events: none;
    overflow: hidden;
    margin-left: 25%;
  }


  .cardbox {
    padding: 20px;
    
        font-family: Gilroy-regular, sans-serif;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
            font-family: Gilroy-regular, sans-serif;
  text-transform: uppercase;

  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
            font-family: Gilroy-regular, sans-serif;
  text-transform: uppercase;

  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
            font-family: Gilroy-regular, sans-serif;
      text-transform: uppercase;

  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
            font-family: Gilroy-regular, sans-serif;
  text-transform: uppercase;

  }

/* 1600 */
  @media (max-width: 1600px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
    margin-left: -10%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -10%;
    left: 10%;
    margin-bottom: 5%;
  }
  .card {
    width: 35rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    margin-left: 5%;
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .card iframe {
    width: 100%;
    height: 500px;
    display:block;  
    pointer-events: none;
    overflow: hidden;
    margin-left: 25%;
  }

  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }

  }


/* 1536 */
@media (max-width: 1536px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
    margin-left: -10%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 110%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -10%;
    left: 5%;
  }
  .card {
    width: 33rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}



/* 1440 */
@media (max-width: 1440px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
    margin-left: -10%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -10%;
    left: 5%;
  }
  .card {
    width: 33rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}



/* 1366 */
@media (max-width: 1366px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.7);
    margin-left: -18%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -10%;
    left: 5%;
  }
  .card {
    width: 30rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}


/* 1200 */
@media (max-width: 1200px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.6);
    margin-left: -28%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -20%;
    left: 2%;
    transform: scale(0.8);
    margin-bottom: -10%;
  }
  .card {
    width: 30rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}

/* 1024 */
@media (max-width: 1024px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.5);
    margin-left: -43%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -30%;
    left: -6%;
    transform: scale(0.65);
    margin-bottom: -20%;
  }
  .card {
    width: 30rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}


/* 1000 */
@media (max-width: 1000px) {
    
  .main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 7px 0 80px;
    flex-wrap: wrap;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.5);
    margin-left: -43%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    width: 15rem;
  }

  .project-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  .project-image {
    width: 100%;
    aspect-ratio: 2.56;
    object-fit: cover;
    object-position: center;
  }
  .project-type {
    color: rgba(71, 71, 71, 0.8);
    margin-top: 20px;
    font: 400 16px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .project-title {
    color: #000;
    margin-top: 5px;
    font: 500 32px Gilroy, sans-serif;
    align-items: center;
    justify-content: center;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .cards {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: -30%;
    left: -16%;
    transform: scale(0.65);
    margin-bottom: -30%;
  }
  .card {
    width: 30rem;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-family: Gilroy-regular, sans-serif;
    
  }
  .card img {
    width: 100%;
    height: 500px;
    display: block;
  }
  .cardbox {
    padding: 20px;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  .cardbox h2 {
    margin: 0;
    font-size: 24px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox h3 {
    margin: 0;
    font-size: 32px;
    color: black;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox p {
    margin: 10px 0;
    font-size: 16px;
    color: #777;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
  .cardbox a {
    text-decoration: none;
    color: #1e90ff;
    font-weight: bold;
    font-family: Gilroy-regular, sans-serif;
    text-transform: uppercase;
  }
}
